var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Plotly',{staticClass:"mt-8",attrs:{"data":[
    {
      x: _vm.observed,
      type: 'histogram',
      name: 'Observed',
      xbins: {
        start: 0,
        end: 9,
        size: 1,
      },
    },
    {
      x: _vm.expectedX,
      y: _vm.expectedY,
      name: 'Expected',
      type: 'line',
      line: { shape: 'hv' },
      mode: 'lines',
    } ],"layout":Object.assign({}, _vm.plotlyLayout,
    {title: { text: _vm.title, font: { color: 'white' }, color: 'white' },
    yaxis: {
      title: {
        text: 'Observed (count)',
        font: {
          color: 'white',
        },
      },
      color: 'white',
    },
    xaxis: {
      title: {
        text: 'Value',
        font: {
          color: 'white',
        },
      },
      color: 'white',
    }}),"type":"histogram"}})}
var staticRenderFns = []

export { render, staticRenderFns }