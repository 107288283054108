var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Plotly',{staticClass:"mt-8",attrs:{"data":[
    {
      x: _vm.simulations,
      type: 'histogram',
      min: 0,
      name: 'Simulated',
      histnorm: 'probability',
      xbins: {
        size: 0.02,
        start: 0,
      },
    } ],"layout":Object.assign({}, _vm.plotlyLayout,
    {title: { text: _vm.title, font: { color: 'white' }, color: 'white' },
    shapes: [
      {
        type: 'line',
        x0: _vm.observed,
        y0: 0,
        x1: _vm.observed,
        yref: 'paper',
        y1: 1,
        line: {
          color: 'orange',
          width: 2,
        },
      },
      {
        type: 'line',
        x0: _vm.threshold,
        y0: 0,
        x1: _vm.threshold,
        yref: 'paper',
        y1: 1,
        line: {
          color: 'lightgreen',
          width: 2,
          dash: 'dot',
        },
      } ],
    annotations: [
      {
        showarrow: false,
        text: 'Obs.',
        align: 'right',
        x: _vm.observed,
        xanchor: 'right',
        y: 0,
        yanchor: 'top',
        textposition: 'top',
        font: { color: 'orange' },
      },
      {
        showarrow: false,
        text: 'Max',
        align: 'right',
        x: _vm.threshold,
        xanchor: 'left',
        y: 0,
        yanchor: 'top',
        textposition: 'bottom',
        font: { color: 'lightgreen' },
      } ],
    yaxis: {
      title: {
        text: 'Prob. of outcome',
        font: {
          color: 'white'
        },
      },
      color: 'white'
    },
    xaxis: {
      title: {
        text: 'K-S Value',
        font: {
          color: 'white'
        },
      },
      color: 'white'
    }}),"type":"histogram"}})}
var staticRenderFns = []

export { render, staticRenderFns }